<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <flairboat-logo />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <b-img fluid :src="$store.state.mainUserCodes.CollegeLogo" alt="Login V2"
            style="max-width:550px; max-height: 300px;" />
          <div class="bg-light-primary p-2 d-flex  w-75 mt-3 rounded-lg">
            <div>
              <h4 class=" font-weight-bold mb-1">Are you a Student?</h4>
              <h6 class=" font-weight-bold">Job hunt on the go with the Flairboat app.</h6>
              <span class="text-body font-medium-1"> Download the app on Android to be the first to know about new
                jobs.</span>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <b-img :src="require('@/assets/images/logo/qrcode.png')" height="100"
                alt="Flairboat application QR code" />
                <a href="https://play.google.com/store/apps/details?id=com.flairboat.flairboat_app" target="_blank" class="font-small-3 font-weight-bold login-text">Download Now</a>
            </div>
          </div>
          <div class="mt-2" v-if="!isMobileLogin">
            <h4 class=" font-weight-bold mb-1">Are you a Recruiter?
              <span class="text-primary font-weight-bold login-text cursor-pointer" @click.prevent="setMobileLogin">
                Login Here.</span>
            </h4>
          </div>
          <div class="mt-2" v-else>
            <h6 class=" mb-1">Are you from Training and Placement office?
              <span class="text-primary font-weight-bold login-text cursor-pointer" @click.prevent="setMobileLogin">
                Login Here.</span>
            </h6>
          </div>

        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2" v-if="!isMobileLogin">
            Are you from Training and Placement office?
          </b-card-title>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2" v-else>
            Are you a Recruiter?
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- <b-alert variant="primary" show>
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">You can login with your work email address or mobile
                    number</span></small>
              </p>
              <p>
                <small class="mr-50">example: xyz@companyname.com</small>
              </p>
            </div>
            <feather-icon v-b-tooltip.hover.left="
              ' You can login with your work email address or mobile number'
            " icon="HelpCircleIcon" size="18" class="position-absolute" style="top: 10; right: 10;" />
          </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }" v-if="!isMobileLogin">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- form 2 -->
          <validation-observer ref="loginForm2" #default="{ invalid }" v-else>
            <b-form class="auth-login-form mt-2" @submit.prevent="loginWithphone">
              <!-- email -->
              <b-form-group label="Mobile Number" label-for="mobile-number">
                <validation-provider #default="{ errors }" name="MobileNumber" vid="mobilenumber"
                  rules="required|numeric|min:10|max:10">
                  <b-input-group class="input-group-merge" prepend="+91"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="mobile-number" v-model="mobilenumber" type="tel" class="form-control-merge"
                      :state="errors.length > 0 ? false : null" name="mobile-number" placeholder="9876543210" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2" v-if="isMobileLogin">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="outline-primary"
              block
              @click.prevent="setMobileLogin"
            >
              <span v-if="!isMobileLogin"> Login with Mobile Number</span>
              <span v-else> Login with Email</span>
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
      <div class="w-100 footer-sign">
        <span class="font-weight-light float-md-right  clearfix mb-0 font-small-3">
          Powered by<b-link class="ml-25 font-weight-normal" href="https://flairboat.com" target="_blank">Flairboat
          </b-link></span>
      </div>
    </b-row>
    <div id="recaptcha-container"></div>
    <modal name="pincodeModal" :clickToClose="false" :adaptive="true">
      <div class="pin-code-wrapper h-100">
        <b-card no-body class="h-100">
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Verify Mobile Number
              </b-card-title>
              <b-card-text>Enter the 6-digit code sent to your mobile number +91-{{
                  mobilenumber
              }}</b-card-text>
            </div>
          </b-card-header>
          <b-card-body class=" justify-content-center ">
            <pincode-input v-model="code" :length="6" class="justify-content-center w-100 mt-1"
              @keyup.native.enter="submitPhoneAuth" :class="{ 'pin-danger': pincodeErrors.length > 1 }" />
            <small class="text-center text-danger">{{ pincodeErrors }}</small>
            <div class="d-flex justify-content-end mt-3 ">
              <b-button variant="outline-secondary" class="mr-2" @click="$modal.hide('pincodeModal')">
                Cancel
              </b-button>
              <b-button variant="primary" @click="submitPhoneAuth">
                Verify
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FlairboatLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BCardSubTitle,
  BCardHeader,
  BCardBody,
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email, numeric, min, max } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import PincodeInput from "vue-pincode-input";
import firebase from "firebase/app";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardText,
    BCard,
    BImg,
    BForm,
    BButton,
    BAlert,
    FlairboatLogo,
    ValidationProvider,
    ValidationObserver,
    PincodeInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      isMobileLogin: true,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      istryingLoading: false,
      // validation rules
      required,
      email,
      mobilenumber: "",
      code: "",
      numeric,
      min,
      max,
      pincodeErrors: ""
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    setMobileLogin() {
      this.isMobileLogin = !this.isMobileLogin;
    },
    loginWithphone() {
      let thisIns = this;

      this.$refs.loginForm2.validate().then((success) => {
        if (success) {
          var phoneNumber = "+91" + this.mobilenumber;
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: function (response) {
                loginWithphone();
              },
            }
          );
          var appVerifier = window.recaptchaVerifier;
          thisIns.$modal.show("pincodeModal");

          firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
              window.confirmationResult = confirmationResult;
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    submitPhoneAuth() {
      let thisIns = this;
      console.log('we are submit phone auth')
      console.log(thisIns.code)
      if (thisIns.code.length == 6) {
        thisIns.pincodeErrors = ""
        confirmationResult
          .confirm(this.code)
          .then((result) => {
            var user = result;
            const payload = {
              userDetails: result,
              notify: this.$toast,
              closeAnimation: (this.istryingLoading = false),
            };
            if (user.user.uid) {
              // console.log(user.user);
              thisIns.$store.dispatch("auth/loginWithPhone", payload);
            }
          })
          .catch((err) => {
            // console.log(err);
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: "Error",
                icon: "AlertIcon",
                variant: "danger",
                text: `${err.message}`,
              },
            });
            thisIns.$modal.hide("pincodeModal");

            // this.$vs.loading.close();
            localStorage.removeItem("userInfo");
            firebase.auth().signOut();
          });
      } else {
        thisIns.pincodeErrors = "Please enter the correct 6-digit pincode"
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.istryingLoading = true;
          const payload = {
            checkbox_remember_me: true,
            userDetails: {
              email: this.userEmail,
              password: this.password,
            },
            notify: this.$toast,
            closeAnimation: (this.istryingLoading = false),
          };
          this.$store.dispatch("auth/loginAttempt", payload);
          if (this.$store.state.auth.isUserLoggedIn) {
            try {
              localStorage.setItem('userData', JSON.stringify(this.$store.state.auth.AppActiveUser))
            } catch (err) {
              this.$refs.loginForm.setErrors(error.response.data.error)
            }
          }

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.footer-sign {
  position: absolute;
  bottom: 1rem;
  z-index: 1;
  right: 3rem;
}

.login-text {
  &:hover {
    color: #039be5 !important;
    transition: all 0.25s ease;
    text-decoration: underline;
  }
}

.input-group-text {
  font-size: 1.143rem;
}

.pin-danger {
  input.vue-pincode-input {
    border: 1px solid #D23A3A !important;

  }
}

input.vue-pincode-input {
  max-width: 50px;
  height: 50px;
  margin: 10px !important;

  &:focus {
    border: 1px solid #039be5;
  }

  // any styles you want for each cell
}

.vm--modal {
  border-radius: 1.143rem;
  background-color: transparent;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
  border: 1px solid #efefef;
}
</style>
